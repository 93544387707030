<template>
  <v-form ref="createForm" v-model="checkValidate">
    <loading :active.sync="loading"></loading>
    <v-row>
      <v-col cols="6" md="6" sm="6">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 700;
            font-size: 31px;
            line-height: 51px;
          "
        >
          Video
        </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" md="6" sm="6" class="text-right">
        <v-btn
          @click="submitAll('q_1_1')"
          color="#2AB3A3"
          dark
          style="padding: 12px 16px; gap: 8px; width: 158px; height: 48px"
          >บันทึก</v-btn
        >
      </v-col>
    </v-row>
    <v-divider style="border: 1px solid #d0d5dd" class="mt-4"></v-divider>
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="12" class="mt-7 mb-7">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
          "
        >
          ลิ้ง Youtube
        </span>
      </v-col>
    </v-row>
    <v-card outlined class="pa-6">
      <v-row>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ชื่อช่อง Youtube</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.name_youtube"
            placeholder="ระบุชื่อช่อง Youtube"
            dense
            outlined
            :rules="rules.required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6"
          ><span class="pt-2">ลิ้ง Youtube</span>
          <span style="color: red">*</span>
          <v-text-field
            hide-details
            v-model="form.link_youtube"
            placeholder="ระบุลิ้ง URL Youtube"
            dense
            outlined
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import DialogBanners from "../Dialogs/DialogBanners.vue";
import DialogBannersEdit from "../Dialogs/DialogBannersEdit.vue";
import DialogGallery from "../Dialogs/DialogGallery.vue";
import DialogGalleryEdit from "../Dialogs/DialogGalleryEdit.vue";
import DialogOldStudents from "../Dialogs/DialogOldStudents.vue";
import DialogOldStudentsEdit from "../Dialogs/DialogOldStudentsEdit.vue";

export default {
  components: {
    draggable,
    Loading,
    DialogBanners,
    DialogBannersEdit,
    DialogGallery,
    DialogGalleryEdit,
    DialogOldStudents,
    DialogOldStudentsEdit,
  },
  data() {
    return {
      checkValidate: true,
      e1: 0,
      method: "",
      dialogBannerEdit: false,
      dialogPictureEdit: false,
      dialogOldStudentEdit: false,
      itemBannerEdit: {},
      itemPictureEdit: {},
      itemOldStudentEdit: {},
      title_en: null,
      title_th: null,
      desc_en: null,
      desc_th: null,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      file: [],
      loading: false,

      comment_desc: "",
      comment_from: "",
      comment_position: "",
      form: {
        name_youtube: "",
        link_youtube: "",
      },
      filesAnnounces: [],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      headersPicture: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อภาพ(ไทย)", value: "title_th", align: "start" },
        // { text: "คำอธิบาย(ไทย)", value: "desc_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersComments: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "รูป", value: "image_url", align: "start" },
        { text: "รายละเอียด(ไทย)", value: "comment_desc_th", align: "start" },
        { text: "คอมเม้นโดย(ไทย)", value: "comment_from_th", align: "start" },
        {
          text: "ระดับการศึกษา(ไทย)",
          value: "level.name_th",
          align: "start",
        },
        {
          text: "สาขาวิชา(ไทย)",
          value: "major.name_th",
          align: "start",
        },
        // { text: "ตำแหน่ง(ไทย)", value: "comment_position_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrandTh: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ชื่อ Banner(TH)", value: "title_th", align: "start" },
        // { text: "คำอธิบาย(ไทย)", value: "desc_th", align: "start" },
        // { text: "สินค้า", value: "product", align: "start" },
        // { text: "ชื่อยี่ห้อ", value: "bannerName", align: "start" },
        // { text: "รายละเอียด", value: "description", align: "start" },
        // { text: "สถานะ", value: "activeFlag", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      items: [],
      itemsComment: [],
      itemsPicture: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
      videoUrl: "",
      showVideo: "",
      nameVideo: "",
      namePic: "",

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    document.title = "Video - GSCM Management System";
    this.getAll();
  },
  methods: {
    deleteFilesAnnounces(val) {
      this.filesAnnounces.splice(val, 1);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    deleteFilesVideo() {
      this.videoUrl = "";
      this.showVideo = "";
      this.nameVideo = "";
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },

    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            this.filesAnnounces.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }

          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },

    async submitAll(q_1_1) {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form,
          filesAnnounces: this.filesAnnounces,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/videoLinkStudents/1`,
          data,
          auth
        );
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/videoLinkStudents`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.form.name_youtube = response.data.data[0].name_youtube;
      this.form.link_youtube = response.data.data[0].link_youtube;
      this.loading = false;
    },

    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/banners/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      // this.getAllbanner();
      this.loading = false;
    },

    closDialogBannerEdit() {
      this.dialogBannerEdit = false;
    },

    async UpdateNewsPicture(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres/${val.id}`,
        auth
      );
      console.log("getOnePicture", response.data.data);
      this.itemPictureEdit = response.data.data;

      this.method = "edit";
      this.dialogPictureEdit = true;
    },
    async ViewNewsPicture(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/imageAtmospheres/${val.id}`,
        auth
      );
      console.log("getOnePicture", response.data.data);
      this.itemPictureEdit = response.data.data;

      this.method = "read";
      this.dialogPictureEdit = true;
    },

    closDialogPictureEdit() {
      this.dialogPictureEdit = false;
    },

    async goToEditComments(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledgeTests/${val.id}`,
        auth
      );
      console.log("getOneStudentComment", response.data.data);
      this.itemOldStudentEdit = response.data.data;

      this.method = "edit";
      this.dialogOldStudentEdit = true;
    },
    async goToViewComments(val) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledgeTests/${val.id}`,
        auth
      );
      console.log("getOneStudentComment", response.data.data);
      this.itemOldStudentEdit = response.data.data;

      this.method = "read";
      this.dialogOldStudentEdit = true;
    },

    closDialogOldStudentEdit() {
      this.dialogOldStudentEdit = false;
    },
  },
};
</script>




