<template>
  <div>
    <ManageVideoStudent />
  </div>
</template>
<script>
import ManageVideoStudent from "@/components/ManageVideoStudent/ManageVideoStudent";
export default {
  components: {
    ManageVideoStudent,
  },
  created() {},
};
</script>
